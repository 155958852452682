import {
  initSentryRoot,
  trackVueErrors,
} from "@solvari/common-fe/integrations";
import Vue from "vue";

import { store } from "@/vue/apps/customer/store/store";

export interface AuthState {
  accessToken: string;
  expiresAt: string;
  permissions: string[];
  refreshToken: string;
  roles: string[];
  user: {
    email: string;
    id: string;
    permissions: string[];
    pro: {
      id: string;
      locale: string;
    };
  } | null;
}

initSentryRoot({
  addData: () => {
    const { auth } = store().state as { auth: AuthState };
    const userStore = auth.user;
    const roleStore = auth.roles;

    return {
      tags: {
        proId: userStore?.pro.id,
        proLocale: userStore?.pro.locale,
        userId: userStore?.id,
      },
      contexts: {
        user: {
          roles: roleStore,
          permission: userStore?.permissions,
        },
      },
    };
  },
  dsn: import.meta.env.VITE_SENTRY_PRO_DSN as string,
  environment: import.meta.env.MODE,
  name: "pro",
  version: import.meta.env.npm_package_version as string,
});

trackVueErrors(Vue);
